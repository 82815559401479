import {
    Component,
    ElementRef,
    Inject,
    Input,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';

import { DOCUMENT } from '@angular/common';
import {
    ApplicationRouterService,
    IParams,
} from './@core/application-router/application-router.service';
import {
    ActivatedRoute,
    NavigationEnd,
    NavigationExtras,
    NavigationStart,
    Router,
} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
    ErrorWrapper,
    ResponseWrapper,
} from './@shared/models/response-wrapper.model';
import { MarcheAPIService } from './@core/api/marche-api.service';
import { plainToClassFromExist } from 'class-transformer';
import { Subscriber } from 'rxjs';
import { Iconable } from './@shared/models/iconable.model';
import { RouteExecutor } from './@core/application-router/route.executor';
import { filter } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { AnalyticsService } from './@core/analytics.service';
import { environment } from '../environments/environment';
import { EventQueue } from './utils/event-queue';
import { SwPush } from '@angular/service-worker';
import { InternationalPopupComponent } from './international-popup/international-popup.component';
import { AuthenticationService } from './@core/authentication/authentication.service';
import { DetailPageService } from './detail-page/detail-page.service';
import { MixPanelService } from './@core/mixpanel.service';
import { userIdentifyService } from './@core/user-identify.service';
import { DeleteAlertPopupComponent } from './bag-page/delete-alert-popup/delete-alert-popup.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent extends RouteExecutor implements OnInit {
    counts: any;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private applicationRouter: ApplicationRouterService,
        private router: Router,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private marcheAPIService: MarcheAPIService,
        private analyticsService: AnalyticsService,
        private detailService: DetailPageService,
        private swPush: SwPush,
        private _renderer2: Renderer2,
        private el: ElementRef,
        private authenticationService: AuthenticationService,
        private mixPanelService: MixPanelService,
        private userIdentify: userIdentifyService
    ) {
        super(applicationRouter);
        this.marcheAPIService.redirectEvent.subscribe((res: string) => {
            this.processLink(res);
        });
        (window as any).addToCartWM = this.addToCartWM.bind(this);
        (window as any).getCartCount = this.getCartCount.bind(this);

        function addTrInEventBody(tracking: string) {
            const eventBody1 = {
                timestamp: new Date().toISOString(),
                link: '/fn/nop?tr=' + tracking,
                screen: window.location.pathname,
            };

            return eventBody1;
        }

        // if ('serviceWorker' in navigator) {
        //   // Wait for the 'load' event to not block other work
        //   window.addEventListener('load', async () => {
        //     // Try to register the service worker.
        //     try {
        //       const reg = await navigator.serviceWorker.register('clevertap_sw.js');
        //       console.log('Service worker registered! 😎', reg);
        //     } catch (err) {
        //       console.log('😥 Service worker registration failed: ', err);
        //     }
        //   });
        // }
        /*    const ref = this;
    // Push Notofication
    // @ts-ignore
    clevertap.notificationCallback = function(msg){
      //raise the notification viewed and clicked events in the callback
      // @ts-ignorev
      clevertap.raiseNotificationViewed();
      const xmlString = msg.msgContent.html;

       // var $button = jQuery("<button></button>");   //element on whose click you want to raise the notification clicked event
      // $button.click(function(){
      //   clevertap.raiseNotificationClicked();
      // });
      ref.openModal(
        'CleverTapPopupComponent',
        '',
        '600px',
        { refresh: undefined, transient: undefined, transition: undefined, popup: 'true'} ,
        {value: xmlString},
      ).then();

    };*/
        //CLEVER TAP PERMISTION POPUP
        // @ts-ignore
        // clevertap.notifications.push({
        //     titleText: 'Would you like to receive Push Notifications?',
        //     bodyText:
        //         'We promise to share only relevant content and keep you updated with your transactions',
        //     okButtonText: 'Go Ahead!',
        //     rejectButtonText: 'No Thanks',
        //     okButtonColor: '#20A87e',
        //     hidePoweredByCT: true,
        //     askAgainTimeInSeconds: 50000, //optional, if not specified, the default value is one week
        // });

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .register('/src/moengage_sw.js')
                .then(registration => {
                    console.log(
                        'MoEngage Service Worker registered:',
                        registration
                    );
                })
                .catch(error => {
                    console.error('Service Worker registration failed:', error);
                });
        }

        this.swPush
            .requestSubscription({
                serverPublicKey: 'Bv',
            })
            .then(sub => {
                console.log(sub, 'subs');
            })
            .catch(err =>
                console.error('Could not subscribe to notifications', err)
            );

        this.swPush.messages.subscribe(message => {
            console.log(message, 'message');
        });

        router.events
            .pipe(filter((e: any) => e instanceof NavigationStart))
            .subscribe((e: NavigationStart) => {
                const lastSlashIndex = e.url.lastIndexOf('/');
                e.url = e.url.split('?')[0];
                let result = e.url.substring(lastSlashIndex + 1, e.url.length);
                if (result === '') {
                    result = 'hp';
                }
                //   this.filterForFooter = result;
            });
        this.activatedRoute.queryParams.subscribe(param => {
            try {
                if (!this.analyticsService.isBot(window.navigator.userAgent)) {
                    let search = window.location.href
                        .toString()
                        .split(window.location.origin.toString())[1];
                    search = search.split('&')[0];

                    if (!search.includes('?f=')) {
                        return;
                    }

                    const tr = this.analyticsService.getTrackingValue(search);
                    if (
                        !!tr &&
                        !this.analyticsService.isBot(window.navigator.userAgent)
                    ) {
                        this.analyticsService.analyticsTracking(tr);
                        const eventBody1 = addTrInEventBody(tr);
                        this.eventQueue.add(eventBody1);
                    }
                }

                if (param?.fbclid) {
                    const date = new Date();
                    date.setDate(date.getDate() + 7);
                    this.document.cookie = `fbclid=${param?.fbclid}; expires=${date}; Secure=true; SameSite=None`;
                }

                const eventBody = {
                    timestamp: new Date().toISOString(),
                    link: window.location.href,
                    screen: window.location.pathname,
                };

                this.eventQueue.add(eventBody);
            } catch (err) {
                console.error(err);
            }
        });

        this.applicationRouter.linkStream.subscribe((res: any) => {
            const { pageId, query, params, data } = res;

            this.dialog.closeAll();
            if (params?.popup !== 'true') {
                document.body.scrollTo({
                    top: 0,
                });
                if (params?.refresh === 'true') {
                    const navigationExtras: NavigationExtras = {
                        queryParams: this.getQueryParams(query),
                        relativeTo: this.activatedRoute,
                        replaceUrl: true,
                    };
                    this.router.navigate([pageId], navigationExtras);
                } else {
                    const navigationExtras: NavigationExtras = {
                        state: data,
                        queryParams: this.getQueryParams(query),
                    };
                    this.router
                        .navigate([pageId], navigationExtras)
                        .then(r => console.warn(r));
                }
            } else {
                let rfpCheck = '/rfp';
                if (pageId.includes('/rfp')) {
                    rfpCheck = pageId;
                }
                switch (pageId) {
                    case '/map':
                        {
                            this.openModal(
                                'ManageAddressPageComponent',
                                '60%',
                                '40%',
                                params,
                                query
                            );
                        }
                        break;
                    case '/success-popup':
                        {
                            this.openModal(
                                'SuccessPopupComponent',
                                '62%',
                                '48%',
                                params,
                                query
                            );
                        }
                        break;
                    case '/prp':
                        {
                            this.openModal(
                                'ReviewPopupComponent',
                                '65%',
                                '40%',
                                params,
                                query
                            );
                        }
                        break;
                    case '/prsp':
                        {
                            this.openModal(
                                'SuccessReviewComponent',
                                '60%',
                                '40%',
                                params,
                                query
                            );
                            const eventBody1 = addTrInEventBody(
                                params.tracking
                            );
                            this.eventQueue.add(eventBody1);
                        }
                        break;
                    case '/aap':
                        {
                            this.openModal(
                                'AddAddressPageComponent',
                                '530px',
                                '40%',
                                params,
                                query
                            );
                        }
                        break;
                    case '/op':
                        {
                            this.openModal(
                                'OfferPageComponent',
                                '80%',
                                '40%',
                                params,
                                query
                            );
                        }
                        break;
                    case '/gp':
                        {
                            this.openModal(
                                'GenericPageComponent',
                                '76%',
                                '47vw',
                                params,
                                query
                            );
                        }
                        break;
                    case '/glp':
                        {
                            this.openModal(
                                'GenericListingPageComponent',
                                '60%',
                                '47%',
                                params,
                                query
                            );
                        }
                        break;
                    case '/olp':
                        {
                            this.openModal(
                                'PlacedOrdersPageComponent',
                                '60%',
                                '40%',
                                params,
                                query
                            );
                        }
                        break;
                    case '/odp':
                        {
                            this.openModal(
                                'OrderDetailPageComponent',
                                '60%',
                                '40%',
                                params,
                                query
                            );
                        }
                        break;
                    case '/ppup':
                        {
                            this.openModal(
                                'CrossSellingPageComponent',
                                '',
                                '',
                                params,
                                query
                            );
                        }
                        break;
                    case '/membershipPopup':
                        {
                            this.openModal(
                                'SubscriptionPopupPageComponent',
                                '',
                                '',
                                params,
                                query
                            );
                        }
                        break;
                    case '/alertMembershipAutoAdd':
                        {
                            this.openModal(
                                'MembershipAddPopupComponent',
                                '',
                                '',
                                params,
                                query
                            );
                        }
                        break;
                    case '/alertMembershipSuccess':
                        {
                            this.openModal(
                                'MembershipSuccessComponent',
                                '',
                                '',
                                params,
                                query
                            );
                        }
                        break;
                    case '/alertpopup':
                        {
                            this.openModal(
                                'SubscriptionRemovePageComponent',
                                '216px',
                                '369px',
                                params,
                                query
                            );
                        }
                        break;
                    case '/easyPayPopup':
                        {
                            this.openModal(
                                'PayLaterPopupComponent',
                                '',
                                '',
                                params,
                                query
                            );
                        }
                        break;
                    case '/ap': {
                        this.openModal(
                            'AuthPageComponent',
                            '60%',
                            '40%',
                            params,
                            query
                        );
                        break;
                    }
                    case '/ap/verify': {
                        this.openModal(
                            'VerifyOtpComponent',
                            '596px',
                            '682px',
                            params,
                            query
                        );

                        break;
                    }
                    case '/hp-popup-login': {
                        console.log(localStorage.getItem('HP-PPUP-SHOW'));
                        this.openModal(
                            'FirstLoginAuthComponent',
                            '596px',
                            '682px',
                            params,
                            query
                        );

                        break;
                    }
                    case '/hp-popup-offer': {
                        this.openModal(
                            'FirstLoginAuthComponent',
                            '596px',
                            '682px',
                            params,
                            query
                        );
                        break;
                    }
                    case '/pip': {
                        let popupHeight = 'auto';
                        if (query.f === 'type~upi') {
                            popupHeight = 'auto';
                        }
                        this.openModal(
                            'PaymentsInputPageComponent',
                            popupHeight,
                            '495px',
                            params,
                            query
                        );

                        const eventBody1 = addTrInEventBody(params.tracking);
                        this.eventQueue.add(eventBody1);

                        break;
                    }
                    case '/review/images': {
                        this.openModal(
                            'ReviewImagePopupComponent',
                            '500px',
                            '600px',
                            params,
                            query
                        );

                        break;
                    }
                    case rfpCheck: {
                        this.openModal(
                            'RequiredFiltersComponent',
                            '600px',
                            '600px',
                            params,
                            query
                        );
                        break;
                    }
                }
            }

            const tracking = params?.tracking as string;
            try {
                if (!!tracking) {
                    this.analyticsService.analyticsTracking(tracking);
                }

                const eventBody = {
                    timestamp: new Date().toISOString(),
                    link: window.location.href,
                    screen: window.location.pathname,
                };
                //  console.log('290', eventBody);
                this.eventQueue.add(eventBody);
            } catch (err) {
                console.error(err);
            }
        });

        this.applicationRouter.functionalStream.subscribe((res: any) => {
            const { query } = res;
            switch (query?.action) {
                case 'nav-update':
                    {
                        console.log(window.location.pathname, query?.page);
                        if (
                            (window.location.pathname.includes(query?.page) &&
                                query?.page !== '/' &&
                                query?.page !== 'dp' &&
                                query?.page !== 'lp' &&
                                query?.page !== 'rfp') ||
                            query?.page === 'page-not-found'
                        ) {
                            this.updateNavigationIcons({ page: query?.page });
                        }
                    }
                    break;
                case 'nav-toggle':
                    {
                        this.navigationShow = query.value !== 'false';
                    }
                    break;
                case 'category-toggle':
                    {
                        this.categoryShow = query.value !== 'false';
                    }
                    break;
                case 'footer-show':
                    {
                        this.footerShow = query.value === 'true';
                    }
                    break;
                case 'bag-count-update':
                    {
                        this.data?.feed.forEach((navObject: any) => {
                            if (navObject?.id === 'bp') {
                                navObject.count = +query?.value;
                            }
                        });
                        this.countViewVisibility = +query?.value !== 0;
                        this.counts = +query?.value;
                    }
                    break;
                case 'back':
                    {
                        window.history.back();
                    }
                    break;
            }
        });
    }

    static readonly NAV_API = '/navigation/v2';
    static readonly APP_HEADER = '/header';
    static readonly identify = '/fn/userinfo';
    static readonly cartCount = '/fn/getCartCount';

    dropdownContentVisible = false;
    dropdownContentParameters: { [p: string]: string | string[] } = {};
    @ViewChild('sidenav') sidenav: MatSidenav | null = null;
    title = 'DailyObjects';
    data: { logo: Iconable; feed: Array<Iconable> } | null = null;
    errors: ErrorWrapper[] = [];
    loading = true;
    navigationShow = false;
    categoryShow = true;
    currentUrl = '';
    previousUrl = '';
    scrollPos = 0;
    footerShow = true;
    showFiller = false;
    filter: any;
    countViewVisibility = false;

    countryPreferenceHeaderVisibility = false;
    preferenceData: any | null = null;

    eventQueue = new EventQueue(this.marcheAPIService);

    readonly COUNTRY_ACCEPTANCE_FLAG = 'COUNTRY_ACCEPTANCE_FLAG';

    ngOnInit() {
        this.applicationRouterService.differNavApiCall.subscribe((res: any) => {
            if (res?.navCall) {
                console.log('differ nav call', res);
                this.updateNavigationIcons({ page: res?.pageId });
            }
        });

        if (!this.analyticsService.isBot(window.navigator.userAgent)) {
            this.websitePreferenceCheck({});
        }
        let websiteCertificateLoad = false;
        try {
            websiteCertificateLoad = environment.trustedWebsiteCertificate;
            if (websiteCertificateLoad) {
                this.includeExternalTrustedScript();
            }
        } catch (e) {}
        this.NectorScriptWidget();
        this.detailService.getcounts().subscribe((res: string) => {
            this.counts = res;
            this.countViewVisibility = this.counts != 0;
        });

        const user_id = localStorage.getItem('username');

        this.authenticationService.getAuthToken().subscribe(token => {
            if (token) {
                this.mixPanelService.identify(user_id || '');
                this.userIdentify.userIdentify();
            }
        });

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                const currentUrl = window.location.href;
                if (currentUrl.includes('?f=')) {
                    return;
                }
                this.handleRouteChange();
            });
    }

    handleRouteChange() {
        const currentUrl = window.location.href;
        const pathname = window.location.pathname;
        const search = currentUrl
            .split(window.location.origin)[1]
            .split('&')[0];

        const tr = this.analyticsService.getTrackingValue(search);

        function addTrInEventBody(tracking: string) {
            const eventBody1 = {
                timestamp: new Date().toISOString(),
                link: '/fn/nop?tr=' + tracking,
                screen: pathname,
            };

            return eventBody1;
        }

        if (!!tr && !this.analyticsService.isBot(window.navigator.userAgent)) {
            this.analyticsService.analyticsTracking(tr);
            const eventBody = addTrInEventBody(tr);
            this.eventQueue.add(eventBody);
        }

        const eventBody = {
            timestamp: new Date().toISOString(),
            link: currentUrl,
            screen: pathname,
        };
        this.eventQueue.add(eventBody);
    }

    getQueryParams(query: { [p: string]: string | string[] }) {
        delete query?.refresh;
        delete query?.transition;
        delete query?.transient;
        delete query?.popup;
        delete query?.tr;
        return query;
    }

    private updateNavigationIcons(query: {}) {
        const subscriber = Subscriber.create<ResponseWrapper<any> | null>(
            value => {
                if (value?.success) {
                    this.data = value.data;
                    let item: Iconable = this.data?.feed.filter(
                        item => item.id == 'bp'
                    )[0] as Iconable;
                    this.countViewVisibility = item.count != 0;
                    this.counts = item.count;
                } else {
                    plainToClassFromExist(this.errors, value?.errors);
                }
            },
            error => {
                console.error(error);
            },
            () => {
                this.loading = false;
            }
        );
        this.marcheAPIService
            .getRequest<ResponseWrapper<any> | null>(
                AppComponent.NAV_API,
                query
            )
            .subscribe(subscriber);
    }

    async openModal(
        popupComponent: any,
        popupHeight: any,
        popupWidth: any,
        params: IParams,
        query: { [p: string]: string | string[] }
    ) {
        let component: any;
        let panelClass = '';
        let position = {};
        const mobileWeb =
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            );
        let disableClose = false;
        const iosWeb = /iPad|iPhone|iPod/.test(navigator.userAgent);
        switch (popupComponent) {
            case 'OfferPageComponent':
                {
                    if (mobileWeb) {
                        popupHeight = '100%';
                        popupWidth = '100vw';
                    }
                    component = await import(
                        /* webpackPrefetch: true */
                        './offer-page/offer-page.component'
                    ).then(m => m.OfferPageComponent);
                }
                break;
            case 'SuccessPopupComponent':
                {
                    if (mobileWeb) {
                        popupHeight = '350px';
                        popupWidth = '600px';
                    }
                    disableClose = true;
                    component = await import(
                        /* webpackPrefetch: true */
                        './success-popup/success-popup.component'
                    ).then(m => m.SuccessPopupComponent);
                }
                break;
            case 'GenericPageComponent':
                {
                    if (mobileWeb) {
                        popupHeight = '100%';
                        popupWidth = '100%';
                    }

                    component = await import(
                        /* webpackPrefetch: true */
                        './generic-page/generic-page.component'
                    ).then(m => m.GenericPageComponent);
                }
                break;

            case 'AddAddressPageComponent':
                {
                    component = await import(
                        /* webpackPrefetch: true */
                        './add-address-page/add-address-page.component'
                    ).then(m => m.AddAddressPageComponent);
                }
                break;
            case 'ManageAddressPageComponent':
                {
                    component = await import(
                        /* webpackPrefetch: true */
                        './manage-address-page/manage-address-page.component'
                    ).then(m => m.ManageAddressPageComponent);
                }
                break;

            case 'PaymentsInputPageComponent':
                {
                    component = await import(
                        /* webpackPrefetch: true */
                        './payments-input-page/payments-input-page.component'
                    ).then(m => m.PaymentsInputPageComponent);
                }
                break;
            case 'GenericListingPageComponent':
                {
                    component = await import(
                        /* webpackPrefetch: true */
                        './generic-listing-page/generic-listing-page.component'
                    ).then(m => m.GenericListingPageComponent);
                }
                break;

            case 'OrderDetailPageComponent':
                {
                    component = await import(
                        /* webpackPrefetch: true */
                        './order-detail-page/order-detail-page.component'
                    ).then(m => m.OrderDetailPageComponent);
                }
                break;

            case 'CrossSellingPageComponent':
                {
                    component = await import(
                        /* webpackPrefetch: true */
                        './detail-page/cross-selling-page/cross-selling-page.component'
                    ).then(m => m.CrossSellingPageComponent);
                    panelClass = iosWeb
                        ? 'my-full-screen-dialog-ios'
                        : mobileWeb
                        ? 'my-full-screen-dialog-others'
                        : '';
                }
                break;
            case 'ReviewPopupComponent':
                {
                    if (mobileWeb) {
                        popupHeight = '';
                        popupWidth = '';
                    }
                    component = await import(
                        /* webpackPrefetch: true */
                        './detail-page/review-popup/review-popup.component'
                    ).then(m => m.ReviewPopupComponent);
                    panelClass = iosWeb
                        ? 'my-full-screen-dialog-ios'
                        : mobileWeb
                        ? 'my-full-screen-dialog-others'
                        : '';
                }
                break;
            case 'ReviewImagePopupComponent':
                {
                    if (mobileWeb) {
                        popupHeight = '';
                        popupWidth = '';
                    }
                    component = await import(
                        /* webpackPrefetch: true */
                        './detail-page/review-image-popup/review-image-popup.component'
                    ).then(m => m.ReviewImagePopupComponent);
                    panelClass = iosWeb
                        ? 'my-full-screen-dialog-ios'
                        : mobileWeb
                        ? 'my-full-screen-dialog-others'
                        : '';
                }
                break;
            case 'SuccessReviewComponent':
                {
                    if (mobileWeb) {
                        popupHeight = '';
                        popupWidth = '';
                    }
                    component = await import(
                        /* webpackPrefetch: true */
                        './detail-page/success-review/success-review.component'
                    ).then(m => m.SuccessReviewComponent);
                    panelClass = iosWeb
                        ? 'my-full-screen-dialog-ios'
                        : mobileWeb
                        ? 'my-full-screen-dialog-others'
                        : '';
                }
                break;
            case 'AuthPageComponent':
                {
                    component = await import(
                        /* webpackPrefetch: true */
                        './authentication-page/auth-page.component'
                    ).then(m => m.AuthPageComponent);
                }
                break;
            case 'VerifyOtpComponent':
                {
                    if (mobileWeb) {
                        popupHeight = '398px';
                        popupWidth = '288px';
                    }
                    const module = await import(
                        './verify-authentication-page/verify-otp.module'
                    ).then(m => m.VerifyOtpModule);
                    component = await import(
                        /* webpackPrefetch: true */
                        './verify-authentication-page/verify-otp.component'
                    ).then(m => m.VerifyOtpComponent);
                }
                break;
            case 'PlacedOrdersPageComponent':
                {
                    component = await import(
                        './placed-orders-page/placed-orders-page.component'
                    ).then(m => m.PlacedOrdersPageComponent);
                }
                break;
            case 'RequiredFiltersComponent':
                {
                    if (mobileWeb) {
                        popupHeight = '80vh';
                        popupWidth = '100%';
                    }
                    component = await import(
                        './req-filters-page/req-filters-page.component'
                    ).then(m => m.ReqFiltersPageComponent);
                    panelClass = iosWeb
                        ? 'my-full-screen-dialog-ios'
                        : mobileWeb
                        ? 'my-full-screen-dialog-others'
                        : '';
                }
                break;
            case 'FirstLoginAuthComponent':
                {
                    if (mobileWeb) {
                        popupHeight = '398px';
                        popupWidth = '288px';
                    }
                    disableClose = true;
                    component = await import(
                        './first-login-auth/first-login-auth.component'
                    ).then(m => m.FirstLoginAuthComponent);
                }
                break;
            case 'OfferVideoPopupComponent':
                {
                    component = await import(
                        /* webpackPrefetch: true */
                        './offer-video-popup/offer-video-popup.component'
                    ).then(m => m.OfferVideoPopupComponent);
                    panelClass = iosWeb
                        ? 'my-full-screen-dialog-ios'
                        : mobileWeb
                        ? 'my-full-screen-dialog-others'
                        : '';
                }
                break;
            case 'SubscriptionPopupPageComponent':
                {
                    component = await import(
                        /* webpackPrefetch: true */
                        './subscription-popup-page/subscription-popup-page.component'
                    ).then(m => m.SubscriptionPopupPageComponent);
                    panelClass = iosWeb
                        ? 'my-full-screen-dialog-ios'
                        : mobileWeb
                        ? 'my-full-screen-dialog-others'
                        : '';
                }
                break;
            case 'SubscriptionRemovePageComponent':
                {
                    component = await import(
                        /* webpackPrefetch: true */
                        './subscription-remove-page/subscription-remove-page.component'
                    ).then(m => m.SubscriptionRemovePageComponent);
                    panelClass = iosWeb
                        ? 'my-full-screen-dialog-ios'
                        : mobileWeb
                        ? 'my-full-screen-dialog-others'
                        : '';
                }
                break;
            case 'MembershipAddPopupComponent':
                {
                    component = await import(
                        /* webpackPrefetch: true */
                        './membership-add-popup/membership-add-popup.component'
                    ).then(m => m.MembershipAddPopupComponent);
                    panelClass = iosWeb
                        ? 'my-full-screen-dialog-ios'
                        : mobileWeb
                        ? 'my-full-screen-dialog-others'
                        : '';
                }
                break;
            case 'MembershipSuccessComponent':
                {
                    component = await import(
                        /* webpackPrefetch: true */
                        './membership-success/membership-success.component'
                    ).then(m => m.MembershipSuccessComponent);
                    panelClass = iosWeb
                        ? 'my-full-screen-dialog-ios'
                        : mobileWeb
                        ? 'my-full-screen-dialog-others'
                        : '';
                }
                break;
            case 'PayLaterPopupComponent':
                {
                    component = await import(
                        /* webpackPrefetch: true */
                        './detail-page/pay-later-popup/pay-later-popup.component'
                    ).then(m => m.PayLaterPopupComponent);
                    panelClass = iosWeb
                        ? 'my-full-screen-dialog-ios'
                        : mobileWeb
                        ? 'my-full-screen-dialog-others'
                        : '';
                }
                break;
        }

        this.dialog.open(component, {
            height: popupHeight,
            width: popupWidth,
            disableClose,
            data: {
                popup: true,
                query,
            },
            panelClass: !!panelClass
                ? panelClass
                : params?.transition === 'center' ||
                  params?.transition === 'auto'
                ? 'desktop-screen-dialog'
                : 'my-full-screen-dialog',
        });
    }

    executePageLink(
        pageId: string,
        query: { [p: string]: string | string[] },
        params?: any
    ) {
        switch (pageId) {
            case '/ddp':
                this.dropdownContentParameters = query;
                this.dropdownContentVisible = true;
                break;
            case '/cp':
                this.sidenav?.close();
                this.dropdownContentVisible = false;
                super.executePageLink(pageId, query, params);
                break;
            default:
                this.dropdownContentVisible = false;
                super.executePageLink(pageId, query, params);
        }
    }

    websitePreferenceCheck(query: {}) {
        if (this.getAcceptedCountryPreferenceFlag()) {
            return;
        }
        const mobileWeb =
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            );
        const iosWeb = /iPad|iPhone|iPod/.test(navigator.userAgent);
        const subscriber = Subscriber.create<ResponseWrapper<any> | null>(
            value => {
                if (value?.success) {
                    this.showCountryPreference(value.data);
                    if (this.preferenceData) {
                        this.dialog.open(InternationalPopupComponent, {
                            data: this.preferenceData,
                            panelClass: iosWeb
                                ? 'my-full-screen-dialog-ios'
                                : mobileWeb
                                ? 'my-full-screen-dialog-others'
                                : '',
                        });
                    }
                } else {
                    plainToClassFromExist(this.errors, value?.errors);
                }
            },
            error => {},
            () => {
                this.loading = false;
            }
        );

        this.marcheAPIService
            .getRequest<ResponseWrapper<any> | null>(
                AppComponent.APP_HEADER,
                query
            )
            .subscribe(subscriber);
    }
    leftPreferenceButton() {
        this.setAcceptedCountryPreference();
        if (!!this.preferenceData.leftButton.button.link) {
            window.location.assign(this.preferenceData.leftButton.button.link);
        }
        this.hideCountryPreference();
    }

    rightPreferenceButton() {
        this.setAcceptedCountryPreference();
        //   this.hideCountryPreference();
        if (!!this.preferenceData.rightButton.button.link) {
            window.location.assign(this.preferenceData.rightButton.button.link);
        }
    }

    private setAcceptedCountryPreference() {
        localStorage.setItem(this.COUNTRY_ACCEPTANCE_FLAG, 'true');
    }

    private getAcceptedCountryPreferenceFlag(): boolean {
        return JSON.parse(
            localStorage.getItem(this.COUNTRY_ACCEPTANCE_FLAG) || 'false'
        );
    }

    private showCountryPreference(preferenceData: object) {
        this.preferenceData = preferenceData;
        this.countryPreferenceHeaderVisibility = true;
    }

    private hideCountryPreference() {
        this.preferenceData = null;
        this.countryPreferenceHeaderVisibility = false;
    }

    closeDropDown() {
        this.dropdownContentVisible = false;
        this.dropdownContentParameters = {};
    }

    includeExternalTrustedScript() {
        const node = document.createElement('script');
        node.async = true;
        node.type = 'text/javascript';
        node.src = 'https://cdn.ywxi.net/js/1.js';
        document.getElementsByTagName('html')[0].appendChild(node);
    }

    NectorScriptWidget() {
        let usernme = this.authenticationService.getUserId();
        let api = environment.nector.APIKey;
        const s1 = this.document.createElement('script');
        s1.setAttribute(
            'src',
            'https://cdn.nector.io/nector-static/no-cache/reward-widget/mainloader.min.js'
        );
        s1.setAttribute('async', '');
        s1.dataset.op = 'widget';
        s1.dataset.api_key = api;
        s1.dataset.customer_id = usernme || undefined;
        s1.dataset.platform = 'custom_website';
        // s1.dataset.websdk_url = "";
        // s1.dataset.platform_url = "";
        // s1.dataset.font_name = "";
        // s1.dataset.font_url = "";
        // this.document.body.appendChild(s1);
        // this.document.getElementsByTagName('html')[0].appendChild(s1);
        // this.d1.nativeElement.insertAdjacentHTML('beforeend',s1 );
        this._renderer2.appendChild(this.el.nativeElement, s1);
    }

    addToCartWM(sku: string) {
        this.authenticationService.getAuthToken().subscribe(token => {
            const tokens = token || '';
            const guestId = localStorage.getItem('x-guest-id') || '';
            this.marcheAPIService.addToCart(sku, tokens, guestId).subscribe(
                response => {
                    console.log('Item added to cart', response);
                },
                error => {
                    console.error('Error adding item to cart', error);
                }
            );
        });
    }

    count = 0;
    getCount(): Promise<number> {
        return new Promise((resolve, reject) => {
            this.marcheAPIService
                .getRequest<ResponseWrapper<any> | null>(AppComponent.cartCount)
                .subscribe({
                    next: value => {
                        if (value?.success) {
                            this.count = value.data.state.count;
                            resolve(this.count);
                        } else {
                            reject(new Error('Failed to fetch cart count'));
                        }
                    },
                    error: err => reject(err),
                });
        });
    }

    async getCartCount() {
        try {
            this.count = await this.getCount();
        } catch (error) {
            console.error('Error fetching cart count:', error);
        }
        return this.count;
    }
}
